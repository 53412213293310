import React from "react";
import { Link } from "react-router-dom";
import img3 from "../assets/images/watermark.jpg";

const Watermark = () => {
  return (
    <div className="h-full w-screen flex flex-col ">
      <div className="flex flex-col items-center  bg-watermark bg-cover bg-no-repeat bg-center  h-60 w-screen md:h-[500px] ">
        <div className="mt-44 md:mt-96">
          <h1 className="text-bold font-extrabold font-head underline decoration-red text-whitee text-4xl md:text-6xl">
            <span className="text-bold font-extrabold font-head underline decoration-red text-black text-4xl md:text-6xl">
              Watermark
            </span>
            Removal
          </h1>
        </div>
      </div>
      <div className="flex flex-row bg-black w-screen justify-between  ">
        <div className="bg-black m-auto w-screen items-center flex  flex-col ">
          <p className="text-whitee font-pg text-start p-2 text-base mt-4 md:w-1/2 md:text-lg">
            At Duckman Auto Detailing, we understand the frustration of
            unsightly watermarks that mar the beauty of your car's exterior. Our
            team of skilled professionals possesses extensive knowledge in the
            art of automotive detailing and specializes in removing watermarks
            with precision and care. We utilize cutting-edge equipment,
            industry-leading products, and meticulous techniques to restore your
            vehicle's flawless finish.
            <p className="text-whitee font-pg text-start p-2 text-base mt-1"></p>
           
          </p>
        </div>

        <div className=" m-auto mt-4 mr-16 hidden md:flex h-[500px] ">
          <img
            className="w-full h-full object-cover rounded-lg "
            src={img3}
            alt=""
          />
        </div>
      </div>
      <div className="flex flex-col bg-black ">
        <h3 className="text-red text-lg font-semibold font-head text-center ">
          Length of time required 2-4 hours
        </h3>
        <div className="bg-white h-auto flex flex-col w-screen items-center  ">
          <div className="p-2 md:w-1/2">
            <h3 className=" font-extrabold font-head text-lg text-start md:text-xl">
            Prepping the Vehicle
            </h3>
            <ul className="text-black text-start list-disc list-inside   text-base font-semibold font-pg md:text-lg">
              
              <li> The vehicle is thoroughly washed and dried to remove any dirt or debris on the surface.</li>
              <li> Special attention is given to the areas with watermarks, ensuring they are clean and ready for treatment.</li>
              <li>The technician determines the most suitable method for removing the watermarks based on the type of surface and the severity of the damage.</li>
              <li>Different techniques may be used, such as chemical treatments, polishing, or buffing.</li>
             
            </ul>
          </div>
          <div className="p-2 md:w-1/2">
            <h3 className=" font-extrabold font-head text-lg text-start md:text-xl">
            Watermark Removal
            </h3>
            <ul className="text-black text-start list-disc list-inside   text-base font-semibold font-pg md:text-lg">
              <li> The selected method is applied to the affected areas.</li>
              <li> The technician works meticulously, targeting each watermark individually.</li>
              <li> The technician works meticulously, targeting each watermark individually.</li>
              <li>
              Once the watermarks are removed, the technician proceeds with polishing the entire vehicle to restore its shine and luster.
              </li>
              <li>A protective coating, such as a sealant or wax, is applied to the vehicle's surface.</li>
              <li>This coating helps to enhance the shine, protect the paint from future watermarks, and provide a barrier against environmental elements. </li>
             
            </ul>
          </div>
          <div className="bg-red rounded-md w-32 h-auto m-auto mt-2 mb-2 ">
            <Link to="/booking">
              <h1 className="text-white p-1 font-semibold text-center text-base font-Prata md:text-l">
                {" "}
                Book Us
              </h1>
            </Link>{" "}
          </div>
          <div className=" mx-2 w-full ">
            <table class="w-full font-pg ">
              <thead class="font-head">
                <tr>
                  <th></th>
                  <th class=" border border-slate-600 ... md:text-lg ">
                    Saloon
                  </th>
                  <th class=" border border-slate-600 ... md:text-lg">
                    Mid-SUV
                  </th>
                  <th class=" border border-slate-600 ... md:text-lg">Suv</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class=" font-head md:text-lg ">Watermark </td>
                  <td class="text-center text-base"> Ksh 5000</td>
                  <td class="text-center text-base"> Ksh 5500</td>
                  <td class="text-center text-base">Ksh 5500</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Watermark;
