import React from 'react'
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "./styles.css";

// import required modules
import { Pagination, Navigation } from "swiper";
import img1 from '../assets/images/paitcorrectionsmall.jpg'
import img2 from '../assets/images/IMG-20220823-WA0006.jpg'
import img3 from '../assets/images/engine.jpg'
import img4 from '../assets/images/headlight.png'
import { Link } from 'react-router-dom';

const Services = () => {
  return (
    <div  className='flex flex-col items-center bg-whitee m-auto w-screen h-auto md:hidden '>
        <div className='mt-4 '>
            <h3 className='font-head font-bold text-4xl underline decoration-red  '>  Services</h3>
        </div>
       
        <Swiper
        slidesPerView={1}
        spaceBetween={30}
        loop={true}
        pagination={false}
        navigation={true}
        modules={[Pagination, Navigation]}
        className="mySwiper"
      >
        <SwiperSlide>
        <div className='flex flex-col items-center w-screen  mt-2 md:w-1/2 '>
           <Link className='flex flex-col items-center' to='/interior'>
           <div className='mb-2'>
                <h3 className='text-xl font-Prata  underline decoration-2 font-thin'> Interior vehicle Detailing</h3>
            </div>
            <div className=' w-4/5 m-auto ' >
                <img  className='w-full h-full object-cover rounded-lg ' src={img2} alt='' />
            </div>
            <div className='mt-2 w-4/5 '>
                <p className='text-black font-pg text-start p-2 text-base mt-4 md:text-xl'>
                    An interior Detail is designed to bring the inside of your vehicle up to standard
                    where by you enjoy settling into the drivers seat and are proud to welcome passengers.It includes
                    vacumming,detailing around the dashboard,interior parts and trims and bringing the windows back to sparkling clarity.
                </p>
            </div></Link>
        </div>
        </SwiperSlide>
        <SwiperSlide>
        <div className='flex flex-col items-center w-screen  mt-2 '>
       <Link className='flex flex-col items-center' to='/paintcorrection'>
       <div>
            <h3 className='text-xl font-Prata  underline decoration-2 font-thin'>Paint Correction /Protection</h3>
        </div>
        <div className=' w-4/5 m-auto ' >
            <img  className='w-full h-full object-cover rounded-lg ' src={img1} alt='' />
        </div>
        <div className='mt-2 w-4/5 '>
            <p className='text-black font-pg text-start p-2 text-base mt-4'>
            Machine Polishing enhances the the appearance of your cars surface colour to give it the
             ultimate shine, it is perfect for preparation to apply a protective coating to maintain the 
             condition of your vehicle.
             </p>
      
        </div></Link>
    </div>
        </SwiperSlide>
        <SwiperSlide>
        <div className='flex flex-col items-center w-screen  mt-2 '>
           <Link className='flex flex-col items-center' to='/engine'>
           <div>
                <h3 className='text-xl font-Prata  underline decoration-2 font-thin'>Engine Steam Detailing</h3>
            </div>
            <div className=' w-4/5 m-auto ' >
                <img  className='w-full h-full object-cover rounded-lg ' src={img3} alt='' />
            </div>
            <div className='mt-2 w-4/5 '>
                <p className='text-black font-pg text-start p-2 text-base mt-4'>
                A clean engine bay gives graet piece of mind and is something we recommend you do periodically ,
        it can also save you time and money in the long term as most importantly your engine is protected from rubber 
        and hose corrosion that can potentially be expensive problems to solve down the line </p>
            </div></Link>
        </div>
        </SwiperSlide>
        <SwiperSlide>
        <div className='flex flex-col items-center w-screen  mt-2 '>
           <Link className='flex flex-col items-center' to='/headlight'>
           <div>
                <h3 className='text-xl font-Prata  underline decoration-2 font-thin'>Headlight Restoration</h3>
            </div>
            <div className=' w-4/5 m-auto ' >
                <img  className='w-full h-full object-cover rounded-lg ' src={img4} alt='' />
            </div>
            <div className='mt-2 w-4/5 '>
                <p className='text-black font-pg text-start p-2 text-base mt-4'>
                Having safe headlights 
      is crucial if youre responsible driver .Weather conditions,such as rain ,fog ,sleet etc can appear without a warning 
      and in case, your headlights are not in good condition could be very dangerous </p>
            </div></Link>
        </div>
        </SwiperSlide>
      </Swiper>
    </div>
  )
}

export default Services
