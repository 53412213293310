import React from "react";
import Slider from "../components/Slider";

const FAQ = () => {
  return (
    <div className=" h-full w-full flex flex-col ">
     <Slider/>
      <div className=" w-full h-full m-auto md:mt-2 text-black  ">
        <h3 className=" font-head text-center text-2xl underline decoration-red decoration-2 md:text-4xl ">Frequently Asked Questions</h3>
      </div>
      <div className=" flex flex-col mx-4 mb-2  gap-4 md:grid md:grid-cols-4 md:mt-4">
      <div className=" w-4/5  m-auto flex flex-col border border-blue rounded-sm  md:w-auto md:h-56 ">
          <h3 className="  mt-2 text-center font-head text-lg underline decoration-red font-extrabold ">
            What services does your business offer?
          </h3>
          <p className=" mx-2 text-left  font-pg font-normal text-base">
            Our business offers a range of detailing services, including
            interior detailing, exterior detailing, and paint protection. We can
            customize a package to meet your specific needs.
          </p>
        </div>
        <div className=" w-4/5  m-auto flex flex-col border border-blue rounded-sm  md:w-auto md:h-56 ">
          <h3 className="  mt-2 text-center font-head text-lg underline decoration-red font-extrabold">
            How do you determine your pricing?
          </h3>
          <p className=" mx-2 text-left  font-pg font-normal text-base">
            Our pricing is based on the size and condition of the vehicle, as
            well as the specific services requested. We would be happy to
            provide a quote after discussing your detailing needs with you.
          </p>
        </div>
        <div className=" w-4/5  m-auto flex flex-col border border-blue rounded-sm  md:w-auto md:h-56 ">
          <h3 className="  mt-2 text-center font-head text-lg underline decoration-red font-extrabold">
            Do you offer mobile detailing services?
          </h3>
          <p className=" mx-2 text-left  font-pg font-normal text-base">
            Yes, we offer mobile detailing services. We can come to your
            location to detail your vehicle.{" "}
          </p>
        </div>
        <div className=" w-4/5  m-auto flex flex-col border border-blue rounded-sm  md:w-auto md:h-56 ">
          <h3 className="  mt-2 text-center font-head text-lg underline decoration-red font-extrabold">
            How long does the detailing process typically take?
          </h3>
          <p className=" mx-2 text-left  font-pg font-normal text-base">
            The detailing process can take anywhere from a few hours to a full
            day, depending on the size and condition of the vehicle and the
            services requested. We will provide an estimated time frame when you
            schedule your appointment.{" "}
          </p>
        </div>
        <div className=" w-4/5  m-auto flex flex-col border border-blue rounded-sm  md:w-auto md:h-56 ">
          <h3 className="  mt-2 text-center font-head text-lg underline decoration-red font-extrabold">
          Can you remove scratches or blemishes from the paint?
          </h3>
          <p className=" mx-2 text-left  font-pg font-normal text-base">
          In many cases, we can remove scratches and blemishes from the paint using specialized techniques and products. 
          However, the success of the repair will depend on the depth and severity of the damage.{" "}
          </p>
        </div>
        <div className=" w-4/5  m-auto flex flex-col border border-blue rounded-sm   md:w-auto h-62 ">
          <h3 className="  mt-2 text-center font-head text-lg underline decoration-red font-extrabold">
          
    Do you have any tips for maintaining the cleanliness and appearance of my vehicle between detailing appointments?
          </h3>
          <p className=" mx-2 text-left  font-pg font-normal text-base">
          To maintain the cleanliness and appearance of your vehicle between detailing appointments, we recommend washing it regularly, protecting the paint with wax or a sealant,
           and keeping the interior clean and free of debris. It can also be helpful to park in a garage or covered area to protect the exterior from the elements.{" "}
          </p>
        </div>
        <div className=" w-4/5  m-auto flex flex-col border border-blue rounded-sm  md:w-auto md:h-56 ">
          <h3 className="  mt-2 text-center font-head text-lg underline decoration-red font-extrabold">
          
          Can you work around my schedule?
          </h3>
          <p className=" mx-2 text-left  font-pg font-normal text-base">
          We will do our best to work around your schedule and find a time that is convenient for you.{" "}
          </p>
        </div>
        <div className=" w-4/5  m-auto flex flex-col border border-blue rounded-sm  md:w-auto md:h-56 ">
          <h3 className="  mt-2 text-center font-head text-lg underline decoration-red font-extrabold">
          
          Do you offer any additional services, such as waxing or headlight restoration?
          </h3>
          <p className=" mx-2 text-left  font-pg font-normal text-base">
          In addition to our standard detailing services, we also offer waxing and headlight restoration.{" "}
          </p>
        </div>
      </div>
    </div>
  );
};

export default FAQ;
