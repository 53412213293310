import React from 'react'
import { Link } from 'react-router-dom'
import img1 from '../assets/images/paitcorrectionsmall.jpg'

const Paintrestoration = () => {
  return (
    <div className="h-full w-screen flex flex-col ">
    <div className="flex flex-col items-center  bg-paintcorrection bg-cover bg-no-repeat bg-center  h-60 w-screen md:h-[500px] ">
      <div className="mt-36 md:mt-96">
        <h1 className="text-bold font-extrabold text-center font-head underline decoration-red text-whitee text-4xl md:text-6xl">
      Paint Protection /Correction
        </h1>
      </div>
    </div>
    <div className="flex flex-row bg-black w-screen justify-between  ">
    <div className="bg-black m-auto w-screen items-center flex flex-col ">
      <p className="text-whitee font-pg text-start p-2 text-base mt-4 md:w-1/2 md:text-lg">
      Machine Polishing enhances the the appearance of your cars surface colour to give it the ultimate shine, it is perfect for preparation to apply a protective coating to maintain the condition of your vehicle.</p>
      <p className="text-whitee font-pg text-start p-2 text-base  md:w-1/2 md:text-lg">Small scratches on the body of your vehicle, discoloured lights, or lost luminaries are all just trivialities that destroy the look of your car.</p>
        <p className="text-whitee font-pg text-start p-2 text-base"></p>
       <p className="text-whitee font-pg text-start p-2 text-base md:w-1/2 md:text-lg"> Paint Correction using various Machine Polishing techniques is available at Duckmanauto Detailing, this is the process of removing and masking the uppermost surface defects such as swirl marks, holograms and minor scratches.
      </p>
    </div>
      
<div className=' m-auto mr-8 hidden md:flex ' >
      <img  className='w-full h-full object-cover rounded-lg ' src={img1} alt='' />
  </div>

</div>
    
    <div className="flex flex-col bg-black ">
      <h3 className="text-red text-lg font-semibold font-head text-center ">Length of time required is  1 Day</h3>
      <div className="bg-white h-auto flex flex-col w-screen items-center  ">
          <div className="p-2 md:w-1/2">
          <h3 className=" font-extrabold font-head text-lg text-start md:text-xl">Paint Protection /Correction</h3>
          <ul className="text-black text-start list-disc list-inside   text-base font-semibold font-pg md:text-lg">
              <li> To help loosen, raise and clear dirt particles from the paintwork without causing micro marks, white snow foam is added to the car, left to sit for a short while, then pressure washed away.</li>
              <li> Using the 2 bucket system, the vehicle is washed using PH neutral shampoo to prevent any stones and sharp edged particles from being carried onto the vehicle via wash mitt.</li>
              <li> Wheels, arches and callipers are cleaned using a Ph neutral cleaner</li>
              <li>Door shuts, petrol cap, arches and lower sections parts will be cleaned </li>
              <li>The vehicle is dried using plush microfibre towels.</li>
              <li> The paintwork is treated with a clay bar removing bonded surface
contaminants </li>
            <li>The paint is treated with an abrasive cutting compound which helps remove any swirl marks and light scratches</li>
            <li>The next step, the paint is polished to a high gloss
IPA is used to remove all traces of oils from the polishing phase to enhance the performance of the sealant </li>
            <li>Exterior plastics and door trims are dressed</li>
            <li>Tyres are dressed</li>
          </ul>
          </div>
          
          <div className='bg-red rounded-md w-32 h-auto m-auto mt-2 mb-2 '>
          </div>
          <div className='bg-red rounded-md w-32 h-auto m-auto mt-2 mb-2 '>
            <Link to='/booking'><h1 className='text-white p-1 font-semibold text-center text-base font-Prata md:text-l'> Book Us</h1>
      </Link> </div>
      <div className=' mx-2 w-full '>
        <table class="w-full font-pg ">
  <thead class="font-head" >
    <tr>
      <th></th> 
      <th class=" border border-slate-600 ... md:text-lg ">Saloon</th>
      <th class=" border border-slate-600 ... md:text-lg">Mid-SUV</th>
      <th class=" border border-slate-600 ... md:text-lg">Suv</th>
    </tr>
  </thead>
  <tbody >
    <tr>
      <td class="font-head md:text-lg">Paint Correction/Buffing</td>
      <td class="text-center text-base">Ksh 5000</td>
      <td class="text-center text-base">Ksh 6000</td>
      <td class="text-center text-base">Ksh 7000</td>
    </tr>
  </tbody>
</table>
        </div>
      </div>
    </div>
  </div>
  )
}

export default Paintrestoration



