import React from 'react'
import { Link } from 'react-router-dom'

const Mottoblock = () => {
  return (
    <div className='bg-red w-screen  h-auto flex flex-col items-center m-auto '>
        <div className='mt-4'>
            <h3 className='text-whitee font-Prata font-bold underline decoration-whitee text-1xl md:text-2xl'> DUCKMAN AUTO DETAILING</h3>
        </div>
        <div className='w-auto h-auto'>
            <p className='p-2 text-center  text-whitee font-pg text-base md:text-xl md:p-4 md:w-1/2 md:m-auto '>
                The Trusted  detailing service  with attention to detail,cutting edge technologies and personal passion
                to ensure we maintain our 100% customer satisfaction.
            </p>
        </div>
        <div className='bg-white rounded-md w-auto h-auto mt-2 mb-2 md:mb-4 '>
            <Link to='/booking'><h1 className='text-black p-1 font-semibold text-base  font-Prata md:text-xl'> Book Us</h1></Link>
        </div>

    </div>
  )
}

export default Mottoblock