import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';

function Gallery() {
  const [media, setMedia] = useState([]);
  const [nextPageToken, setNextPageToken] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = useCallback(async () => {
    const ACCESS_TOKEN = 'IGQWRQR0ZAqUmNHcW9ZAVWdpYzlVNksxeUlibHJDTE5pZAWdPdklIR1ptVE9YMEJjajlONUhrUThEV0NRem9UZA2tDRFltTWdlZAWYxdzJxeEVKZAGF3akVxSEtkOEtVYURiNlZAWOEc2VExXdnliZAmYyWWhYVGlocXZAjQmMZD';
    const url = nextPageToken
      ? `https://graph.instagram.com/me/media?fields=id,caption,media_type,media_url,thumbnail_url,permalink&access_token=${ACCESS_TOKEN}&after=${nextPageToken}`
      : `https://graph.instagram.com/me/media?fields=id,caption,media_type,media_url,thumbnail_url,permalink&access_token=${ACCESS_TOKEN}`;

    setIsLoading(true);
    try {
      const response = await axios.get(url);
      const responseData = response.data;

      setMedia((prevMedia) => [...prevMedia, ...responseData.data]);
      setNextPageToken(responseData.paging.next);
    } catch (error) {
      console.error('Error fetching media:', error);
    } finally {
      setIsLoading(false);
    }
  }, [nextPageToken]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    function handleScroll() {
      if (
        window.innerHeight + document.documentElement.scrollTop ===
        document.documentElement.offsetHeight
      ) {
        fetchData();
      }
    }

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [fetchData]);

  return (
    <div>
      <div className="flex flex-col mx-4 mb-2 gap-4 md:grid md:grid-cols-4 md:mt-4">
        {media.map((item) => (
          <div key={item.id}>
            {item.media_type === 'IMAGE' && (
              <img key={item.id} src={item.media_url} alt={item.caption} />
            )}
            {item.media_type === 'CAROUSEL_ALBUM' && (
              <img src={item.media_url} alt={item.caption} />
            )}
            {item.media_type === 'VIDEO' && (
              <video controls>
                <source src={item.media_url} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            )}
          </div>
        ))}
      </div>
      {isLoading && <p>Loading...</p>}
      {nextPageToken && <button onClick={fetchData}>Load More</button>}
    </div>
  );
}

export default Gallery;
