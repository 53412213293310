import { Menu } from "@mui/icons-material";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../assets/images/duckmanalogo.png";

import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import EmailIcon from '@mui/icons-material/Email';
import "./Nav.css";
import Servicemenu from "./Servicemenu";

const Navbar = ({setShowMenu,showMenu}) => {
  
  const [servicemenu, setServicemenu] = useState(false);
  return (
    <div className="  w-full h-16 md:h-auto  ">
      
      {servicemenu ? (
        <Servicemenu setServicemenu={setServicemenu} servicemenu={servicemenu}  />
      ) : null}
     
      <div className="flex   justify-between items-center bg-white w-full h-auto  font-Prata font-medium text-lg md:h-20" >
        
          <div className="h-12 align-middle mx-auto mt-[4px] md:self-start md:ml-16 ">

          <Link to='/'><img className="w-14  h-14 md:w-16 md:h-16" src={logo} alt="" /> </Link>
          
          </div>

          
      <div className="hidden mt-[6px]  items-center m-auto   md:flex ">
        <ul className="text-black   flex gap-4 text-lg  items-center font-bold font-pg ">
    
          <li className="cursor-pointer"><div className=" font-head cursor-pointer  hover:underline hover:decoration-red hover:decoration-4  "  onClick={() => setServicemenu(!servicemenu)}>
            Services</div>
            
          </li>
          <li><Link to="/blogs" className="font-head  hover:underline hover:decoration-red hover:decoration-4">
            Blog
          </Link></li>
          <li><Link to="/contact" className="font-head  hover:underline hover:decoration-red hover:decoration-4">
           Contact
          </Link></li>
         <li> <Link to="/gallery" className="font-head  hover:underline hover:decoration-red hover:decoration-4">
            Gallery
          </Link></li>
          <li> <Link to="/faq" className="font-head  hover:underline hover:decoration-red hover:decoration-4">
            FAQS
          </Link></li>
          <li> <Link to="/pricing" className="font-head  hover:underline hover:decoration-red hover:decoration-4">
            Pricing
          </Link></li>
          <li> <Link to="/packages" className="font-head  hover:underline hover:decoration-red hover:decoration-4">
            Packages
          </Link></li>
          <li><Link to="/booking" className="font-head  hover:underline hover:decoration-red hover:decoration-4"><div className='rounded-md bg-red w-auto h-auto flex  items-center '>
                    <h3 className='p-1 text-white font-head text-base'>Book Today</h3>
                </div></Link></li>
                
        </ul>
      </div>
      <div className=" flex-row  font-head text-base gap-2 mr-8 hidden md:flex text-red">
      <div className=" ">
      <a href='https://wa.me/<+254702982035>'><WhatsAppIcon className="  text-[#075E54] rounded" />  +254702982035</a>
            </div>
            <div className="font-pg font-extrabold">
              <EmailIcon className=" text-main rounded text-black " /> info@duckmanautodetailing.com
            </div>
          
      </div>
     

       

        <div className=" right-0 mr-4 md:hidden">
          <Menu
            onClick={() => setShowMenu(!showMenu)}
            fontSize="large"
            className="text-red "
          />
        </div>
      </div>
    </div>
  );
};

export default Navbar;
