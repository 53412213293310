import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import img1 from '../assets/images/paitcorrectionsmall.jpg'
import img4 from '../assets/images/engine.jpg'
import img3 from '../assets/images/IMG-20220823-WA0006.jpg'

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "./styles.css";

// import required modules
import { Pagination, Navigation,Autoplay } from "swiper";

export default function Slider() {
  return (
    
     <div className="h-full w-screen flex flex-col ">
       <Swiper
        slidesPerView={1}
        spaceBetween={30}
        loop={true}
        pagination={{
          clickable: true,
        }}
        autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
        navigation={false}
        modules={[Pagination, Navigation,Autoplay]}
        className="w-screen flex flex-col h-screen"
      >
        <SwiperSlide>
            <div className='flex flex-col items-center   h-60 w-screen md:h-[500px] '>
        <div className='w-full h-full'>
         <img src={img3} alt=''/>
        </div>
        <div  className=' -mt-12 md:-mt-24'>
        
          <h1 className='text-bold font-extrabold font-head underline decoration-red text-whitee text-4xl md:text-6xl'>Interior Steam Clean</h1>
        
      </div>
    </div>
    </SwiperSlide>
        <SwiperSlide>
        <div className='flex flex-col items-center   h-60 w-screen md:h-[500px]'>
        <div className='w-full h-full'>
         <img src={img1} alt=''/>
        </div>
        <div  className=' -mt-12 md:-mt-24'>
        
          <h1 className='text-bold font-head  font-extrabold underline decoration-red text-whitee text-4xl md:text-6xl'>Paint Correction</h1>
        
      </div>
    </div>
        </SwiperSlide>
        <SwiperSlide>
        <div className='flex flex-col items-center   h-60 w-screen md:h-[500px]'>
        <div className='w-full h-full'>
         <img src={img4} alt=''/>
        </div>
        <div  className=' -mt-12 md:-mt-24'>
        
          <h1 className='text-bold font-head  font-extrabold underline decoration-red  text-whitee text-4xl md:text-6xl'>Engine Steam Detailing</h1>
        
      </div>
    </div>
        </SwiperSlide>
       
      </Swiper>
     </div>
   
  );
}
