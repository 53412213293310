import React, { useEffect, useState } from 'react'
import {  useParams } from "react-router-dom";
import { doc, onSnapshot } from "firebase/firestore";
import { db } from "../Firebase";


const Blog=()=>{

  const { id } = useParams();

  const [blog, setBlog] = useState([]);
  useEffect(() => {
    const docRef = doc(db, "blogs", id);
    onSnapshot(docRef, (snapshot) => {
      setBlog({ ...snapshot.data(), id: snapshot.id });
    });
  }, [id]);
  console.log(blog);
	return(

	<div className='w-full h-full flex flex-col mb-20 items-center'>

	  <div className=" flex  flex-col  items-center mt-6 w-screen h-full lg:w-screen md:mx-8  ">
    <h3 className='font-bold font-head align-middle text-2xl text-black-darker mt-4  '>{blog.title}</h3>
    <h3 className='font-[200]  font-Prata align-right text-m text-black-darker mt-4  '>{blog.date}  </h3>

       <div className='flex  flex-col  items-center lg:flex lg:flex-row '>
       <div className='w-full h-2/4 mb-4 p-2 mx-4 rounded-md md:w-[800px] lg:h-[800px] lg:ml-12'>
					<img src= {blog.imageUrl} alt="" className="w-full rounded-md md:h-full object-cover md:-ml-8 mt-8  " />

				</div>
          <div className="  w-full ml-4    flex items-start  font-bold text-sm  lg:mr-4 border-none  md:w-4/5   ">
            <p className=" first-letter:text-4xl whitespace-pre-line first-letter:font-bold font-pg m-auto p-2 font-[100] text-base md:text-lg text-black ">
              {" "}
             {blog.description}
            </p>
        </div>
       </div>
      </div>
	</div>



		)
};

export default Blog
