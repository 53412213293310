import React from 'react'
import { Link } from 'react-router-dom'
import img1 from '../assets/images/headlight.png'


const Headlight = () => {
  return (
    <div className="h-full w-screen flex flex-col ">
    <div className="flex flex-col items-center  bg-headlight bg-cover bg-no-repeat bg-center  h-60 w-screen md:h-[500px] ">
      <div className="mt-36 md:mt-96">
        <h1 className="text-bold font-extrabold text-center font-head underline decoration-red text-whitee text-4xl md:text-6xl">
      Headlight Restoration
        </h1>
      </div>
    </div>
    <div className="flex flex-row bg-black w-screen justify-between  ">
    <div className="bg-black m-auto w-screen items-center flex flex-col ">
      <p className="text-whitee font-pg text-start p-2 text-base  md:w-1/2 md:text-lg">Having safe headlights 
      is crucial if youre responsible driver .Weather conditions,such as rain ,fog ,sleet etc can appear without a warning 
      and in case, your headlights are not in good condition could be very dangerous   .</p>
  
      <p className="text-whitee font-pg text-start p-2 text-base mt-0 md:w-1/2 md:text-lg">
        Safe headlight protect your family  and other passengers .Headlight restoration is a long-lasting 
        inexpensive service that can help you see the road better and avoid dangerous situations.
      </p>

        
    </div>
      
<div className=' m-auto mr-12 hidden md:flex mt-4  ' >
      <img  className='w-full h-full object-cover rounded-lg ' src={img1} alt='' />
  </div>

</div>
    
    <div className="flex flex-col bg-black ">
      <h3 className="text-red text-lg font-semibold font-head text-center ">Length of time required 2-3 hrs</h3>
      <div className="bg-white h-auto flex flex-col w-screen items-center  ">
          <div className="p-2 md:w-1/2">
          <h3 className=" font-extrabold font-head text-lg text-start md:text-xl">Headlihght Restoration Process </h3>
          <ul className="text-black text-start list-disc list-inside   text-base font-semibold font-pg md:text-lg">
              <li>We will use a series of wet and dry abrasives to first remove oxidation,then commence refinement process 
              </li>
              <li>
                Once removed ,we will then restore like-new shine via a two stage matchine polishng process 
              </li>
              <li> Finally we will remove all polish residues using steam</li>
              <li>A UV protection layer  is applied  </li>
             
            
          </ul>
          </div>
          
          <div className='bg-red rounded-md w-32 h-auto m-auto mt-2 mb-2 '>
          </div>
          <div className='bg-red rounded-md w-32 h-auto m-auto mt-2 mb-2 '>
            <Link to='/booking'><h1 className='text-white p-1 font-semibold text-center text-base font-Prata md:text-l'> Book Us</h1>
      </Link> </div>
      <div className=' mx-2 w-full '>
        <table class="w-full font-pg ">
  <thead class="font-head" >
    <tr>
      <th></th> 
      <th class=" border border-slate-600 ... md:text-lg ">Saloon</th>
      <th class=" border border-slate-600 ... md:text-lg">Mid-SUV</th>
      <th class=" border border-slate-600 ... md:text-lg">Suv</th>
    </tr>
  </thead>
  <tbody >
    <tr>
      <td class=" font-head md:text-lg ">Headlight Restoration </td>
      <td class="text-center text-base"> Ksh 4000</td>
      <td class="text-center text-base"> Ksh 4000</td>
      <td class="text-center text-base">Ksh 4000</td>
    </tr>
    
  </tbody>
</table>
        </div>
      </div>
    </div>
  </div>
  )
}

export default Headlight