import {  Close,ArrowDownward } from '@mui/icons-material'
import React from 'react'
import { useState } from "react";
import { Link } from 'react-router-dom'

const Mobilemenu = ({setShowMenu,showMenu}) => {

  const[service,setService]=useState(false);
  return (

     <div className='  flex flex-col  bg-white w-full h-[1920px]  lg:hidden'>
          <div className='mx-auto mt-3 mb-8  '>
          < Close className='text-red' onClick={() => setShowMenu(!showMenu)} fontSize='large'/>
          </div>
          <div className=' flex  bg-white flex-col font-Prata   mx-auto  items-center  w-full  font-bold text-xl text-black-darker focus:bg-none '>
          < Link to="/" className='font-head mb-2' onClick={() => setService(!service)}>Services <ArrowDownward/></Link>
          {
            service?
            <div onClick={() => setShowMenu(!showMenu)} className=' flex  bg-white flex-col font-head  gap-6 mx-auto   items-center  w-full  font-bold text-xl text-black-darker focus:bg-none' >
          <Link to="/interior" className='font-head'> Interior Steam Detailing </Link>
          <Link to="/paintcorrection" className='font-head'>Paint Correction/Protection</Link>
          <Link to="/engine" className='font-head'>Engine Steam Detailing</Link>
          <Link to="/headlight" className='font-head'>Headlight Restoration</Link>
          <Link to="/watermark"  className='font-head mb-6' >Watermark Removal</Link>
          </div>:null
          }
          <div  onClick={() => setShowMenu(!showMenu)} className= ' flex  bg-white flex-col font-head  gap-6 mx-auto   items-center  w-full  font-bold text-xl text-black-darker focus:bg-none' >
          <Link to="/blogs" className='font-head'>Blogs</Link>
          <Link to="/gallery" className='font-head'>Gallery</Link>
          <Link to="/contact"  className='font-head'>Contact</Link>
          <Link to="/pricing"  className='font-head'>Pricing</Link>
          <Link to="/packages"  className='font-head'>Packages</Link>
          <Link to="/faq"  className='font-head'>FAQS</Link>
          <Link to="/booking"  className='font-head'>Book Us</Link>
          </div>

        </div>

    </div>
   )
}

export default Mobilemenu
