import React from 'react';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import ShieldIcon from '@mui/icons-material/Shield';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';

const Whychoose = () => {
  return (
    <div className='bg-whyus bg-opacity-95 md:bg-fixed bg-cover bg-no-repeat bg-center items-center w-screen h-auto flex flex-col md:h-[500px]'>
      <div className='items-center w-screen h-auto flex flex-col my-2 m-auto'>
        <h3 className='text-4xl underline text-red decoration-4 decoration-white text-center font-head font-bold mt-4'>Why Choose Us</h3>
        <div className='items-center w-screen h-auto flex flex-col gap-2 mt-2 md:flex-row md:mt-4 md:gap-12 md:w-11/12'>
          <div className='flex flex-col items-center w-11/12 h-auto bg-black bg-opacity-70 border-solid border-whitee border-2 rounded-lg md:w-1/4 flex-grow'>
            <div className='mt-4 align-middle m-auto w-20 h-20 rounded-full bg-black flex flex-col items-center'>
              <Diversity3Icon fontSize='large' className='w-full h-full mt-4 text-whitee object-cover' />
            </div>
            <div className='mt-2 text-whitee text-base'>
              <h3 className='font-head'>Professional and Knowledgeable</h3>
            </div>
            <div>
              <p className='text-whitee font-pg text-center text-base p-2 mt-2 md:text-lg'>
                Our team of detailing experts come from all different backgrounds.
                It is without a doubt that our team of professionals with different levels of skills and
                experience. Rest assured your car is in safe hands throughout the whole process.
              </p>
            </div>
          </div>
          <div className='flex flex-col items-center w-11/12 bg-black bg-opacity-70 border-solid border-whitee border-2 rounded-lg h-auto md:w-1/4 flex-grow'>
            <div className='mt-4 align-middle m-auto w-20 h-20 rounded-full bg-black flex flex-col items-center'>
              <ShieldIcon fontSize='large' className='w-full h-full mt-4 text-whitee object-cover' />
            </div>
            <div className='mt-2 text-whitee text-base'>
              <h3 className='font-head'>Secure</h3>
            </div>
            <div>
              <p className='text-whitee font-pg text-center text-base p-2 md:text-lg'>
                Safety of your vehicle is a number one priority while on our property. We've taken
                extra measures of protection, including proper storage of client belongings and a checklist for customers to confirm their items.
              </p>
            </div>
          </div>
          <div className='flex flex-col items-center w-11/12 h-auto bg-black bg-opacity-70 border-solid border-whitee border-2 rounded-lg md:w-1/4 flex-grow'>
            <div className='mt-4 align-middle m-auto w-20 h-20 rounded-full bg-black flex flex-col items-center'>
              <ThumbUpIcon fontSize='large' className='w-full h-full mt-4 text-whitee object-cover' />
            </div>
            <div className='mt-2 text-whitee text-base'>
              <h3 className='font-head'>Friendly and Attentive</h3>
            </div>
            <div>
              <p className='text-whitee font-pg text-center text-base p-2 mt-2 md:text-lg'>
                We take pride in each procedure and talk you through all aspects to ensure you're comfortable
                with your decision. Customer satisfaction is our highest focus. Book a consultation today!
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Whychoose;
