import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { collection, deleteDoc, doc, onSnapshot, orderBy, query } from "firebase/firestore";
import { auth,db } from "../Firebase";
import { Delete } from "@mui/icons-material";
import { useAuthState } from "react-firebase-hooks/auth";
const Blogcard = () => {
  const [blogs, setBlogsList] = useState([]);
  const [user] = useAuthState(auth);

  
  const deleteblog = useCallback(async(id)=>{
   
      await deleteDoc(doc(db, "blogs", id));
  
  },[]);

  useEffect(() => {
    
    const blogsCollectionRef = collection(db, "blogs");
    const q = query(blogsCollectionRef, orderBy("date", "desc"));
    onSnapshot(q, (snapshot) => {
      const blog = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
     
      setBlogsList(blog);
      console.log(blog);
    });
  }, [deleteblog]);
  return (
	<div className='flex flex-col h-auto m-auto items-center w-screen bg-white gap-40  justify-between md:grid md:grid-cols-3  md:gap-28'>
      {blogs.map((blog) => (
        <>
        <Link to={`/blog/${blog.id}`}>
          
          <div
            key={blog.id}
            className="relative flex flex-col m-auto w-4/5 items-center rounded  md:w-[300px] md:h-[350px]  "
          >
            <img
              src={blog.imageUrl}
              alt=""
              className="w-full h-full object-cover rounded "
            />
            <div className=" absolute -bottom-24 h-60 w-11/12 md:h-[250px] md:w-[280px] bg-white flex flex-col items-center">
              <h3 className="font-[200]  font-Prata align-right text-m text-black-darker mt-4">
                {blog.date}
              </h3>
              <p className="m-auto font-head text-lg">{blog.title} </p>

              <div className="  h-8 w-32  bg-main   flex items-center  font-bold text-sm mx-6  border-none">
                <p className="font-Prata m-auto text-sm text-white ">
                  {" "}
                  Read More ...
                </p>
              </div>
              
                    {user  && (
                        <div
                      onClick={() => {
                        deleteblog(blog.id);
                      }}
                      className="mt-2"
                    >
                      <Delete fontSize="medium" />
                    </div>
                      )}
            </div>
            
          </div>
          
        </Link>
        </>
      ))}
    </div>
  );
};

export default Blogcard;

  
  


