import React from "react";
import { Link } from "react-router-dom";
import Blogcard from "../components/Blogcard";

const Bloglist = () => {
  return (
   

    <div className='flex flex-col mb-32 items-center w-screen h-auto justify-between bg-white  '>
         <h3 className='font-bold underline underline-offset-2 mb-4 mx-4  decoration-main font-head align-middle  text-5xl text-black-darker mt-14  '>  Expert Auto Detailing Insights, Techniques, and Product Recommendations.</h3>
         <div className='flex flex-col h-auto items-center w-screen  gap-40  justify-between lg:grid lg:ml-8 lg:grid-cols-3  lg:gap-28'>
            <Link to='/blog'><Blogcard/></Link>
           
           

        </div>

    </div>


    
  );
};

export default Bloglist;
