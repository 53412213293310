import React from "react";
import { Link } from "react-router-dom";
import img3 from '../assets/images/IMG-20220823-WA0006.jpg'



const Interior = () => {
  return (
    <div className="h-full w-screen flex flex-col ">
      <div className="flex flex-col items-center  bg-interior bg-cover bg-no-repeat bg-center  h-60 w-screen md:h-[500px] ">
        <div className="mt-44 md:mt-96">
          <h1 className="text-bold font-extrabold font-head underline decoration-red text-whitee text-4xl md:text-6xl">
            Interior Steam Clean
          </h1>
        </div>
      </div>
      <div className="flex flex-row bg-black w-screen justify-between  ">
      <div className="bg-black m-auto w-screen items-center flex  flex-col ">
        <p className="text-whitee font-pg text-start p-2 text-base mt-4 md:w-1/2 md:text-lg">
          Saturated dry steam is the way forward, it is a 100% green technology
          that makes sanitisation possible without the use of harsh chemicals,
          using less water and producing less wastewater. Caring for the
          cleanliness of your car’s interior has never been easier!
          <p className="text-whitee font-pg text-start p-2 text-base mt-1"></p>
          We have chemical-free interior detailing available to aid in the
          removal of bacteria and allergens using high pressurised hot steam
          paired with natural, yet relentless, cleaning products – guaranteed to
          make you feel you at ease whilst driving your car that feels like new
          again! It is the perfect solution to kill and remove all bacteria and
          any lingering odours.
        </p>
      </div>
      
      <div className=' m-auto mt-2 mr-8 hidden md:flex ' >
            <img  className='w-full h-full object-cover rounded-lg ' src={img3} alt='' />
        </div>

      </div>
      <div className="flex flex-col bg-black ">
        <h3 className="text-red text-lg font-semibold font-head text-center ">Length of time required 2-4 hours</h3>
        <div className="bg-white h-auto flex flex-col w-screen items-center  ">
            <div className="p-2 md:w-1/2">
            <h3 className=" font-extrabold font-head text-lg text-start md:text-xl">Interior</h3>
            <ul className="text-black text-start list-disc list-inside   text-base font-semibold font-pg md:text-lg">
                <li> Dry Vaccum</li>
                <li> Shampoo of carpets removing any dirt and stain</li>
                <li> Dashboard,trims and vents steam cleaned</li>
                <li>Contact points such as steering wheel deep cleaned </li>
                <li>Upholstered seats are shampooed and steamed</li>
                <li> Or leather seats are cleaned and conditioned</li>
            </ul>
            </div>
            <div className="p-2 md:w-1/2">
            <h3 className=" font-extrabold font-head text-lg text-start md:text-xl">Exterior</h3>
            <ul className="text-black text-start list-disc list-inside   text-base font-semibold font-pg md:text-lg">
                <li> Non contact pre wash and rinse too loosen dirt</li>
                <li> Wheels deep cleaned</li>
                <li> Underarches,doors shuts,fuel  caps cleaned</li>
                <li>Snowfoam applied with soft brush used to agitate intricate areas </li>
                <li>2 bucket contact wash</li>
                <li>Windows cleaned and polished using microfibre towels </li>
                <li>Tyres Dressed and excess water wiped down</li>
            </ul>
            </div>
            <div className='bg-red rounded-md w-32 h-auto m-auto mt-2 mb-2 '>
            <Link to='/booking'><h1 className='text-white p-1 font-semibold text-center text-base font-Prata md:text-l'> Book Us</h1>
      </Link> </div>
      <div className=' mx-2 w-full '>
        <table class="w-full font-pg ">
  <thead class="font-head" >
    <tr>
      <th></th> 
      <th class=" border border-slate-600 ... md:text-lg ">Saloon</th>
      <th class=" border border-slate-600 ... md:text-lg">Mid-SUV</th>
      <th class=" border border-slate-600 ... md:text-lg">Suv</th>
    </tr>
  </thead>
  <tbody >
    <tr>
      <td class=" font-head md:text-lg ">Interior Detailing </td>
      <td class="text-center text-base"> Ksh 5000</td>
      <td class="text-center text-base"> Ksh 6600</td>
      <td class="text-center text-base">Ksh 7200</td>
    </tr>
  </tbody>
</table>
        </div>
        </div>
      </div>
    </div>
  );
};

export default Interior;
