import React from 'react'
import { Link } from 'react-router-dom'

const Servicemenu = ({setServicemenu,servicemenu}) => {
  return (
    <div className='absolute hidden ml-80 mt-11 md:flex w-screen  ' >
        <div className="  text-lg flex fle-row gap-2" onClick={() => setServicemenu(!servicemenu)} >
            <Link to="/interior" className="  font-head  hover:underline decoration-red hover:decoration-4">
            Interior Steam Detailing
          </Link>
          <Link to="/paintcorrection" className=" font-head  hover:underline decoration-red hover:decoration-4">
            Paint Potection/Correction
          </Link>
          <Link to="/engine" className="  font-head  hover:underline decoration-red hover:decoration-4">
            Engine Steam Detailing
          </Link>
          <Link to="/headlight" className="  font-head  hover:underline decoration-red hover:decoration-4">
            Headlight Restoration
          </Link>
          
            </div>
    </div>
  )
}

export default Servicemenu