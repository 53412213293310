import React from "react";
import { Link } from "react-router-dom";


const FAQS = () => {
  return (
    <div className=" h-full w-full flex  md:mb-4 flex-col ">
      <div className=" w-full h-full m-auto md:mt-2 text-black  ">
        <h3 className=" font-head text-center text-2xl underline decoration-red decoration-2 md:text-4xl ">Frequently Asked Questions</h3>
      </div>
      <div className=" flex flex-col m-auto mb-2  gap-4 md:grid md:grid-cols-4 md:mt-4 md:w-auto md:mx-2">
        <div className=" w-4/5  m-auto flex flex-col border border-blue rounded-sm  md:w-auto h-52 ">
          <h3 className="  mt-2 text-center font-head text-lg underline decoration-red font-extrabold ">
            What services does your business offer?
          </h3>
          <p className=" mx-2 text-left  font-pg font-normal text-base">
            Our business offers a range of detailing services, including
            interior detailing, exterior detailing, and paint protection. We can
            customize a package to meet your specific needs.
          </p>
        </div>
        <div className="w-4/5  m-auto flex flex-col border border-blue rounded-sm md:w-auto h-52  ">
          <h3 className="  mt-2 text-center font-head text-lg underline decoration-red font-extrabold">
            How do you determine your pricing?
          </h3>
          <p className=" mx-2 text-left  font-pg font-normal text-base">
            Our pricing is based on the size and condition of the vehicle, as
            well as the specific services requested. We would be happy to
            provide a quote after discussing your detailing needs with you.
          </p>
        </div>
        <div className=" w-4/5  m-auto flex flex-col border border-blue rounded-sm md:w-auto h-52   ">
          <h3 className="  mt-2 text-center font-head text-lg underline decoration-red font-extrabold">
            Do you offer mobile detailing services?
          </h3>
          <p className=" mx-2 text-left  font-pg font-normal text-base">
            Yes, we offer mobile detailing services. We can come to your
            location to detail your vehicle.{" "}
          </p>
        </div>
        <div className=" w-4/5  m-auto flex flex-col border border-blue rounded-sm  md:w-auto h-52   ">
          <h3 className="  mt-2 text-center font-head text-lg underline decoration-red font-extrabold">
            How long does the detailing process typically take?
          </h3>
          <p className=" mx-2 text-left  font-pg font-normal text-base">
            The detailing process can take anywhere from a few hours to a full
            day, depending on the size and condition of the vehicle and the
            services requested. We will provide an estimated time frame when you
            schedule your appointment.{" "}
          </p>
        </div>
      </div>
      <Link to="/faq" className="font-head mb-2  m-auto hover:underline hover:decoration-red hover:decoration-4 md:mb-4"><div className='rounded-md bg-red w-24 h-auto flex  items-center '>
                    <h3 className='text-center m-auto text-white font-head text-base'>Read More</h3>
                </div></Link>
    </div>
  );
};

export default FAQS;
