import React from "react";
import { Link } from "react-router-dom";
import img3 from "../assets/images/engine.jpg";

const Engine = () => {
  return (
    <div className="h-full w-screen flex flex-col ">
      <div className="flex flex-col items-center  bg-engine bg-cover bg-no-repeat bg-center  h-60 w-screen md:h-[500px] ">
        <div className="mt-36 md:mt-96">
          <h1 className="text-bold font-extrabold text-center font-head underline decoration-red text-whitee text-4xl md:text-6xl">
            Engine Steam Detailing
          </h1>
        </div>
      </div>
      <div className="flex flex-row bg-black w-screen justify-between  ">
        <div className="bg-black m-auto w-screen items-center flex flex-col ">
          <p className="text-whitee font-pg text-start p-2 text-base  md:w-1/2 md:text-lg">
            Your Engine works hard to get you around but gets dirty over time
            and can even spring a leak.
          </p>

          <p className="text-whitee font-pg text-start p-2 text-base mt-0 md:w-1/2 md:text-lg">
            A clean engine bay gives graet piece of mind and is something we
            recommend you do periodically , it can also save you time and money
            in the long term as most importantly your engine is protected from
            rubber and hose corrosion that can potentially be expensive problems
            to solve down the line
          </p>
        </div>

        <div className=" m-auto mr-8 hidden md:flex mt-4 ">
          <img
            className="w-full h-full object-cover rounded-lg "
            src={img3}
            alt=""
          />
        </div>
      </div>

      <div className="flex flex-col bg-black ">
        <h3 className="text-red text-lg font-semibold font-head text-center ">
          Length of time required 2-3 hrs
        </h3>
        <div className="bg-white h-auto flex flex-col w-screen items-center  ">
          <div className="p-2 md:w-1/2">
            <h3 className=" font-extrabold font-head text-lg text-start md:text-xl">
              Engine Steam Detailing{" "}
            </h3>
            <ul className="text-black text-start list-disc list-inside   text-base font-semibold font-pg md:text-lg">
              <li> Temprature is checked aroud the engine</li>
              <li>
                {" "}
                Sensitive areas such as the battery ,alternator,air filter etc
                are protected with plastic and electric tape
              </li>
              <li> Engined is rinsed using light pressure</li>
              <li>Steam is now used to soften debris and dirt</li>
              <li>
                All purpose cleaner is now applied and worked in with soft
                bristle detailing brush
              </li>
              <li>Engine is rinsed to lift away dirt </li>
              <li>Components are now carefully dried </li>
              <li>We now run the engine for 1-2 min to confirm issues </li>
              <li>Dressing is now applied to protect and rejuvenate</li>
            </ul>
          </div>

          <div className="bg-red rounded-md w-32 h-auto m-auto mt-2 mb-2 "></div>
          <div className="bg-red rounded-md w-32 h-auto m-auto mt-2 mb-2 ">
            <Link to="/booking">
              <h1 className="text-white p-1 font-semibold text-center text-base font-Prata md:text-l">
                {" "}
                Book Us
              </h1>
            </Link>{" "}
          </div>
          <div className=' mx-2 w-full '>
        <table class="w-full font-pg ">
  <thead class="font-head" >
    <tr>
      <th></th> 
      <th class=" border border-slate-600 ... md:text-lg ">Saloon</th>
      <th class=" border border-slate-600 ... md:text-lg">Mid-SUV</th>
      <th class=" border border-slate-600 ... md:text-lg">Suv</th>
    </tr>
  </thead>
  <tbody >
    
    <tr>
      <td class="font-head md:text-lg">Engine Steam Detailing</td>
      <td class="text-center text-base">Ksh 2000</td>
      <td class="text-center text-base">Ksh 2000</td>
      <td class="text-center text-base">Ksh 2500</td>

    </tr>
    
  </tbody>
</table>
        </div>
        </div>
      </div>
    </div>
  );
};

export default Engine;
