export function setupIntersectionObserver() {
   // Check if Intersection Observer is supported
if ('IntersectionObserver' in window) {
    const config = {
      root: null, // Use the viewport as the root
      rootMargin: '0px',
      threshold: 0.1
    };
  
    const observer = new IntersectionObserver(onEntry, config);
    const entries = document.querySelectorAll('.observe');
    entries.forEach(entry => observer.observe(entry));
  }
  
  function onEntry(entry) {
    entry.forEach(change => {
      if (change.isIntersecting) {
        change.target.classList.add('element-is-visible');
      } else {
        change.target.classList.remove('element-is-visible');
      }
    });
  }
  
  }
  