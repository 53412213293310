
import { useState } from 'react';
import {BrowserRouter, Route, Routes } from 'react-router-dom';
import Blog from './pages/Blog';
import Footer from "./components/Footer";
import Mobilemenu from './components/Mobilemenu';
import Navbar from "./components/Navbar";
import FAQ from './pages/FAQ';
import Bloglist from './pages/Bloglist';
import Book from './pages/Book';
import Contact from './pages/Contact';
import Engine from './pages/Engine';
import Gallery from './pages/Gallery';
import Headlight from './pages/Headlight';
import Homepage from "./pages/Homepage";
import Interior from './pages/Interior';
import Paintrestoration from './pages/Paintrestoration';
import Watermark from './pages/Watermark';
import Pricing from './pages/Pricing';
import ProtectedRoutes from './components/ProtectedRoutes';
import Blogadd from './components/Blogadd';
import Login from './components/Login';
import Package from './components/Package';


function App() {
  const [showMenu, setShowMenu] = useState(false);
  return (
    <div className=" App relative">
      <BrowserRouter>
      {showMenu ? (
        <Mobilemenu setShowMenu={setShowMenu} showMenu={showMenu}  />
      ) : null}
      <Navbar setShowMenu={setShowMenu} showMenu={showMenu}/>
      
      <Routes>
      <Route path='/' element={<Homepage/>}/>
      <Route path='/interior' element={<Interior/>}/>
      <Route path='/engine' element={<Engine/>}/>
      <Route path='/blogs' element={<Bloglist/>}/>
      <Route path='/blog/:id' element={<Blog/>}/>
      <Route path='/login' element={<Login/>}/>
      <Route path='/blogadd' element={<ProtectedRoutes><Blogadd/></ProtectedRoutes>}/>
      <Route path='/headlight' element={<Headlight/>}/>
      <Route path='/watermark' element={<Watermark/>}/>
      <Route path='/paintcorrection' element={<Paintrestoration/>}/>
      <Route path='/contact' element={<Contact/>}/>
      <Route path='/booking' element={<Book/>}/>
      <Route path='/gallery' element={<Gallery/>}/>
      <Route path='/blogs' element={<Bloglist/>}/>
      <Route path='/blog/:id' element={<Blog/>}/>
      <Route path='/pricing' element={<Pricing/>}/>
      <Route path='/faq' element={<FAQ/>}/>
      <Route path='/packages' element={<Package/>}/>
      </Routes>
     
     <Footer/>
      </BrowserRouter>
     
     
   
    </div>
  );
}

export default App;
