import React from "react";
import { Navigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../Firebase";


const ProtectedRoutes = ({ children }) => {
    let [user] = useAuthState(auth);
  if (!user) {
    return <Navigate to="/login"/>;
  }
  return children;
};

export default ProtectedRoutes