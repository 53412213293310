// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
import {getFirestore } from 'firebase/firestore'
import {getStorage} from 'firebase/storage'
import {getAuth} from "firebase/auth";


// Your web app's Firebase configuration
const firebaseConfig = {

    apiKey: "AIzaSyBubgYYdx8-_H9ZEjmI5PCgnUB1rnz6rgU",
  
    authDomain: "duckmanauto.firebaseapp.com",
  
    projectId: "duckmanauto",
  
    storageBucket: "duckmanauto.appspot.com",
  
    messagingSenderId: "895267538078",
  
    appId: "1:895267538078:web:39f3c975443175f2ba678f",
  
    measurementId: "G-5VNVTSEL1F"
  
  };
  

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const storage =getStorage(app);
export const db = getFirestore(app);
export const auth = getAuth(app);

