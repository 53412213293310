import React, { useState } from "react";
import { Timestamp, collection, addDoc } from "firebase/firestore";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { storage, db } from "../Firebase";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import { Link, useNavigate } from "react-router-dom";



export default function Blogadd() {
  let navigate = useNavigate();
  const date = firebase.firestore.Timestamp.now()
    .toDate()
    .toString()
    .slice(4, 15);
  console.log(date);
  
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    image: "",
    createdAt: Timestamp.now().toDate(),
  });

  const [progress, setProgress] = useState(0);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleImageChange = (e) => {
    setFormData({ ...formData, image: e.target.files[0] });
  };

  const handlePublish = (e) => {
  	e.preventDefault()
    if (!formData.title || !formData.description || !formData.image) {
      alert("Please fill all the fields");
      return;
    }

    const storageRef = ref(
      storage,
      `/images/${Date.now()}${formData.image.name}`
    );

    const uploadImage = uploadBytesResumable(storageRef, formData.image);

    uploadImage.on(
      "state_changed",
      (snapshot) => {
        const progressPercent = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setProgress(progressPercent);
      },
      (err) => {
        console.log(err);
      },
      () => {
        setFormData({
          title: "",
          description: "",
          image: "",
        });

        getDownloadURL(uploadImage.snapshot.ref).then((url) => {
          const articleRef = collection(db, "blogs");
          addDoc(articleRef, {
            title: formData.title,
            description: formData.description,
            imageUrl: url,
            date,
          })
            .then(() => {
              
              setProgress(0);
            })
            .catch((err) => {
              
            });
            navigate("/blogs");
        });
      }
    );
  };

  return (
    <div className="flex flex-col items-center w-screen h-screen mb-4">
     
          <h2 className="font-bold underline underline-offset-2  decoration-main font-Prata align-middle text-xl text-black-darker mt-14  ">Create Blog Post</h2>
          
        
            <div className="flex flex-col w-11/12  lg:w-1/2">
            <label className="text-start font-Prata" htmlFor="">Title</label>
            <input
              type="text"
              name="title"
              className="outline-none border-black p-1 font-Prata border-solid border-2 rounded"
              value={formData.title}
              onChange={(e) => handleChange(e)}
            />
            </div>
          

          {/* description */}

          <div className="flex flex-col  w-11/12 mt-2  lg:w-1/2">
          <label htmlFor="" className="font-Prata">Article</label>
          <textarea 
            name="description"
            className="h-[300px] outline-none p-2 font-Prata border-black border-solid border-2 rounded whitespace-pre-line"
            value={formData.description}
            onChange={(e) => handleChange(e)}
          />
          </div>

         <div className="flex flex-col  self-start ml-4 mt-2 lg:ml-[350px]  ">
           {/* image */}
           <label htmlFor="" className="font-Prata">Article Image</label>
          <input
            type="file"
            name="image"
            accept="image/*"
            className="form-control"
            onChange={(e) => handleImageChange(e)}
          />
         </div>

          {progress === 0 ? null : (
            <div className=" mt-4 w-11/12">
             
                {` ${progress}%`}
            </div>
          )}
          

          <button 
            className="w-20 h-8 rounded self-start ml-4 mt-4 bg-main lg:ml-[350px] "
            onClick={handlePublish}
          >
            Publish
          </button>

         <Link to='/imageadd'>
         <button 
            className="w-20 h-8 rounded text-white mb-10  self-start ml-4 mt-12 bg-black lg:ml-[50px] "
            
          >
            ImageAdd
          </button></Link>
        
    
     
    </div>
  );
}