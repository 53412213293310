import React from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "./styles.css";
import { Pagination, Navigation } from "swiper";
import img1 from '../assets/images/paitcorrectionsmall.jpg';
import img2 from '../assets/images/IMG-20220823-WA0006.jpg';
import img3 from '../assets/images/engine.jpg';
import img4 from '../assets/images/headlight.png';
import img7 from '../assets/images/watermark.jpg';
import { Link } from 'react-router-dom';

const Servicedeskt = () => {
  return (
    <div className='hidden flex-col items-center bg-whitee m-auto w-screen h-[600px] md:flex'>
      <div className='mt-4'>
        <h3 className='font-head font-bold text-4xl underline decoration-red'>Services</h3>
      </div>

      <Swiper
        slidesPerView={1}
        spaceBetween={30}
        loop={true}
        pagination={false}
        navigation={true}
        modules={[Pagination, Navigation]}
        className="mySwiper"
      >
        <SwiperSlide>
          <div className='flex flex-row items-center w-screen mt-2'>
            <div className='flex flex-col items-center w-screen h-[500px] mt-2'>
              <Link className='flex flex-col items-center' to='/interior'>
                <div>
                  <h3 className='text-xl font-Prata underline decoration-2 font-thin'>Interior Steam Detailing</h3>
                </div>
                <div className='w-4/5 m-auto'>
                  <img className='w-full h-full object-cover rounded-lg' src={img2} alt='' />
                </div>
                <div className='mt-2 w-4/5'>
                  <p className='text-black font-pg text-start p-2 text-base mt-4'>
                    An interior Detail is designed to bring the inside of your vehicle up to standard
                    where you enjoy settling into the driver's seat and are proud to welcome passengers. It includes
                    vacuuming, detailing around the dashboard, interior parts and trims, and bringing the windows back to sparkling clarity.
                  </p>
                </div>
              </Link>
            </div>
            <div className='flex flex-col items-center w-screen h-[500px] mt-2'>
              <Link className='flex flex-col items-center' to='/paintcorrection'>
                <div>
                  <h3 className='text-xl font-Prata underline decoration-2 font-thin'>Paint Correction /Protection</h3>
                </div>
                <div className='w-4/5 m-auto'>
                  <img className='w-full h-full object-cover rounded-lg' src={img1} alt='' />
                </div>
                <div className='mt-2 w-4/5'>
                  <p className='text-black font-pg text-start p-2 text-base mt-4'>
                    Machine Polishing enhances the appearance of your car's surface color to give it the
                    ultimate shine, it is perfect for preparation to apply a protective coating to maintain the
                    condition of your vehicle.
                  </p>
                </div>
              </Link>
            </div>
            <div className='flex flex-col items-center w-screen h-[500px] mt-2'>
              <Link className='flex flex-col items-center' to='/engine'>
                <div>
                  <h3 className='text-xl font-Prata underline decoration-2 font-thin'>Engine Steam Detailing</h3>
                </div>
                <div className='w-4/5 m-auto'>
                  <img className='w-full h-full object-cover rounded-lg' src={img3} alt='' />
                </div>
                <div className='mt-2 w-4/5'>
                  <p className='text-black font-pg text-start p-2 text-base mt-4'>
                    A clean engine bay gives great peace of mind and is something we recommend you do periodically.
                    It can also save you time and money in the long term as most importantly your engine is protected from rubber
                    and hose corrosion that can potentially be expensive problems to solve down the line.
                  </p>
                </div>
              </Link>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className='flex flex-row items-center w-screen mt-2'>
            <div className='flex flex-col items-center w-screen h-[500px] mt-2'>
              <Link className='flex flex-col items-center' to='/headlight'>
                <div>
                  <h3 className='text-xl font-Prata underline decoration-2 font-thin'>Headlight Restoration</h3>
                </div>
                <div className='w-4/5 h-[250px] m-auto'>
                  <img className='w-full h-full object-cover rounded-lg' src={img4} alt='' />
                </div>
                <div className='mt-2 w-4/5'>
                  <p className='text-black font-pg text-start p-2 text-base mt-4'>
                    Having safe headlights is crucial if you're a responsible driver. Weather conditions, such as rain, fog, sleet, etc., can appear without a warning,
                    and in case your headlights are not in good condition, it could be very dangerous.
                  </p>
                </div>
              </Link>
            </div>
            <div className='flex flex-col items-center w-screen h-[500px] mt-2'>
              <Link className='flex flex-col items-center' to='/watermark'>
                <div>
                  <h3 className='text-xl font-Prata underline decoration-2 font-thin'>Watermark Removal</h3>
                </div>
                <div className='w-4/5 m-auto h-64'>
                  <img className='w-full h-full object-cover rounded-lg' src={img7} alt='' />
                </div>
                <div className='mt-2 w-4/5'>
                  <p className='text-black font-pg text-start p-2 text-base mt-4'>
                    
            Unsightly watermarks that mar the beauty of your car's exterior. Our
            team of skilled professionals possesses extensive knowledge in the
            art of automotive detailing and specializes in removing watermarks
            with precision and care. We utilize cutting-edge equipment,
            industry-leading products, and meticulous techniques to restore your
            vehicle's flawless finish.
                  </p>
                </div>
              </Link>
            </div>
            <div className='flex flex-col items-center w-screen h-[500px] mt-2'>
              <Link className='flex flex-col items-center' to='/engine'>
                <div>
                  <h3 className='text-xl font-Prata underline decoration-2 font-thin'>Engine Steam Detailing</h3>
                </div>
                <div className='w-4/5 m-auto'>
                  <img className='w-full h-full object-cover rounded-lg' src={img3} alt='' />
                </div>
                <div className='mt-2 w-4/5'>
                  <p className='text-black font-pg text-start p-2 text-base mt-4'>
                    A clean engine bay gives great peace of mind and is something we recommend you do periodically.
                    It can also save you time and money in the long term as most importantly your engine is protected from rubber
                    and hose corrosion that can potentially be expensive problems to solve down the line.
                  </p>
                </div>
              </Link>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default Servicedeskt;
