import React from 'react'

const Statement = () => {
  return (
    <div className='bg-statment  w-screen  md:bg-fixed bg-cover bg-no-repeat bg-center h-auto flex flex-col items-center md:h-[500px] md:items-end ' >
        <div className=' bg-opacity-50 bg-black  flex flex-col gap-1 md:text-lg text-white h-50 text-center p-4 mx-2 my-2  font-pg font-thin border border-whitee md: md:mr-4 md:mt-24 md:w-[600px] ml-2    ' >
            <p className='font-pg font-thin  text-base md:text-lg' >
                At Duckman auto detailing  we take pride in each project we take ,from protection to restoration ,you can be safe
                in the knowledge that your car in good hands,our eco-friendly solutions provide unbelievable results!
            </p>
            <p className='mt-4  '>
                USED CAR? NEW CAR? CLASSIC CAR?
                SUPERCAR?
            </p>
            <p className='mt-4'>
                WE HAVE GOT YOU COVERED!
            </p>

        </div>
        <div className='hidden gap-2 md:flex m-auto mr-4  '>
            <div className=''>
                <div className='rounded-md bg-red w-32 h-14 flex  items-center '>
                    <h3 className='p-1 text-white font-head text-base'>#interiordetailing</h3>
                </div>
            </div>
            <div className=''>
                <div className='rounded-md bg-red w-32 h-14 flex  items-center '>
                    <h3 className='p-1 text-white font-head text-base'>#steamcleaning</h3>
                </div>
            </div>
            <div className=''>
                <div className='rounded-md bg-red w-32 h-14 flex  items-center '>
                    <h3 className='p-1 text-white font-head text-base'>#enginesteadetailing</h3>
                </div>
            </div>
            <div className=''>
                <div className='rounded-md bg-red w-32 h-14 flex  items-center '>
                    <h3 className='p-1 text-white font-head text-base'>#paintprotection</h3>
                </div>
            </div>
            <div className=''>
                <div className='rounded-md bg-red w-32 h-14 flex  items-center '>
                    <h3 className='p-1 text-white font-head text-base'>#headlightrestoration</h3>
                </div>
            </div>

        </div>
        
        
    </div>
  )
}

export default Statement