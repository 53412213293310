import { signInWithEmailAndPassword } from "firebase/auth";
import React, { useState } from "react";
import { auth } from "../Firebase";
import { useNavigate } from "react-router-dom";


export default function Login() {
  let navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleLogin = async () => {
    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate("/blogadd");
    } catch (error) {
      
    }
  };
  return (
    <div className="flex flex-col items-center w-screeen h-screen"
    style={{maxWidth:400, marginTop:60}}
    >
      <h1 className="self-center font-Prata">Login</h1>
      <div className="flex flex-col w-11/12  lg:w-1/2">
        <label>Email</label>
        <input
          type="email"
          className="outline-none border-black p-1 font-Prata border-solid border-2 rounded"
          placeholder="Enter your email"
          onChange={(e) => {
            setEmail(e.target.value);
          }}
        />
      </div>

      <div className="flex flex-col w-11/12  lg:w-1/2">
        <label>Password</label>
        <input
          type="password"
          className="outline-none border-black p-1 font-Prata border-solid border-2 rounded"
          placeholder="Password"
          onChange={(e) => {
            setPassword(e.target.value);
          }}
        />
      </div>
      <br />
      <button className="w-20 h-8 rounded self-start ml-4 mt-4 bg-main lg:ml-[350px]" onClick={handleLogin}>
        Login
      </button>
    </div>
  );
}
